<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-tooltip text="Assign Clinic">
      <vs-button v-if="inTabEdit" @click="getClinics" id="assignNurseToClinic">Assign Clinic</vs-button>
      <vs-button
        v-else
        @click="getClinics"
        size="small"
        type="border"
        icon-pack="feather"
        icon="icon-send"
      ></vs-button>
    </vx-tooltip>
    <vs-popup title="Assign Clinics" :active.sync="popupActivo">
      <div v-if="showSelect">
        <vs-select
          placeholder="Select Clinic"
          label="Clinics"
          multiple
          id='clinicsSelect'
          autocomplete
          v-model="clinicIds"
          class="w-full m-2"
        >
          <vs-select-item
            :key="index"
            :value="item._id"
            :text="item.clinicName ? item.clinicName : item.firstName"
            v-for="(item, index) in clinics"
          />
        </vs-select>

        <vs-button
          @click="assignClinic"
          color="primary"
          id='confirmRequest'
          class="m-2"
          type="border"
          >Assign</vs-button
        >
      </div>

      <div v-else>
        <vs-alert color="warning">Further clinics are not available.</vs-alert>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "assign-nurse",
  props: {
    nurseId: {
      type: String,
      default: null,
    },
    inTabEdit: {
      type: Boolean,
      default: false,
    },
    associatedIds: {
      type: Array,
    },
  },
  data() {
    return {
      popupActivo: false,
      clinics: [],
      clinicIds: [],
      clinicAvailable: true,
      showSelect: true,
    };
  },
  methods: {
    ...mapActions("franchise", ["assignClinics", "fetchAssignClinics"]),
    async assignClinic() {
      let self = this;

      if (this.clinicIds.length <= 0) {
        self.$vs.notify({
          title: "Validation Failed",
          text: "Please select clinics to assign.",
          color: "danger",
        });
        return false;
      }

      this.$vs.loading();

      this.assignClinics({
        nurseId: this.nurseId,
        clinicIds: this.clinicIds,
      })
        .then(async (res) => {
          self.$vs.notify({
            title: "Clinic Assigned",
            text: "Clinic Assigned Successfully.",
            color: "success",
          });
          this.popupActivo = false;
          // this.clinicIds = [];
          this.$vs.loading.close();
          await this.$emit("fetchList");
        })
        .catch((err) => {
          console.log("Error :::", err);
          this.$vs.loading.close();
        });
    },
    getClinics() {
      this.$vs.loading();
      let self = this;
      this.popupActivo = true;
      this.fetchAssignClinics({ nurseId: this.nurseId })
        .then((res) => {
          if (res.data.data.length <= 0) this.showSelect = false;
          else {
            this.clinics = res.data.data;
            this.showSelect = true;
          }
          if (self.clinics.length <= 0) {
            self.clinicAvailable = false;
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          self.$vs.notify({
            title: "Error",
            text: "Error Fetching data from server. Please reload the page and try again.",
            color: "danger",
          });
          this.popupActivo = false;
          this.$vs.loading.close();
        });
    },
  },
  created() {
    // this.clinicIds = this.associatedIds;
  },
  watch: {
    associatedIds: {
      immediate: true,
      handler(val, oldVal) {
        this.clinicIds = val;
      },
    },
  },
};
</script>
